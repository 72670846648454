#hero
  display: flex
  justify-content: center
  align-items: center
  height: 100vh
  background: #999 url('../../assets/images/hero.webp') center center no-repeat
  background-blend-mode: multiply
  background-size: cover
  &.homepage
    background: #C591B5 url('../../assets/images/hero.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
  &.fordesigners
    background: #999 url('../../assets/images/bg-d.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
  &.foragencies
    background: #3A5AB4 url('../../assets/images/bg-a.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
  &.forbusiness
    background: #999 url('../../assets/images/bg-b.webp') center center no-repeat
    background-size: cover
  &.contest
    background: #A846C4 url('../../assets/images/bg-m.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
  &.error
    background: #3A5AB4 url('../../assets/images/error.webp') center center no-repeat
    background-blend-mode: hard-light
    background-size: cover
  &.thankyou
    background: #A846C4 url('../../assets/images/thanks.webp') center center no-repeat
    background-blend-mode: multiply
    background-size: cover
