.title
  margin: 0
  padding: 0
  +v-xs
    font-size: 1.2em
  &-cta
    font-size: 4em
    font-weight: 200
    +v-xs
      font-size: 2.5em
    &-mini
      font-size: 2em
      font-weight: 200 !important
  &-intro
    font-size: 4.25em
    font-weight: 400
    +v-xs
      font-size: 2.5em
    &-sub
      font-size: 1.75em
      font-weight: 200
  &-section
    font-size: 2em
    font-weight: 400
    line-height: 1.25
    letter-spacing: 3%
    text-transform: uppercase
    +v-xs
      font-size: 1.5em
    &-base
      font-size: 1em
      font-weight: 500
    &-mini
      text-transform: uppercase
    &-sub
      font-size: 1.35em
      font-weight: 300
      line-height: 1.4
      text-transform: none
      +v-xs
        font-size: 1.05em
        &.w-64
          width: 80% !important
          min-width: 80% !important
          max-width: 80% !important
  &-mini
    font-size: .75em
    font-weight: 300
    line-height: 1.4
    text-transform: uppercase
  &-name
    font-size: 1.1em
    font-weight: 400
    line-height: 1.4
  &-item
    font-size: 1.3em
    font-weight: 400
    line-height: 1.4
  &-creator
    font-size: 1.2em
    font-weight: 400
    &:hover
      color: $theme !important
    +v-xs
      font-size: .85em
      text-align: left !important
      &.c-grey
        text-align: right !important
        transform: translateY(-5px)

.text
  &-card
    font-size: 1.35em
    font-weight: 400
    line-height: 1.25
