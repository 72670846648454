.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 60px
  padding: 0 40px
  font-size: 1.05em
  font-weight: 400
  text-transform: uppercase
  border-radius: 4px
  transition: all ease-out .25s
  border: none
  outline: none !important
  &.small
    height: 40px
    padding: 0 24px
    font-size: .85em !important
    border-radius: 4px
    +v-xs
      padding: 10px !important
  &.outline
    height: 36px
    padding: 1px 16px 0
    font-size: 0.95em
    font-weight: 400
    background-color: $start
  +v-sm
    display: flex
    width: 60%
    margin: 24px auto
  +v-xs
    display: flex
    width: 100%
    margin: 24px 0
    padding: 0
    font-size: 0.82em
